body, .root, .dashboard, .login {
  min-height: 100vh;
}

.dashboard {
  width: 100%;
  display: flex;
  color: #8D8D8D ;
}

.slide {
  width: 200px;
  border-right: 1px solid #D4D4D4;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #242A38;
}

.slide-perfil {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  color: #FDD429;
}

.slide-perfilImagen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  aspect-ratio: 1/1;
  border: 5px solid #FDD429;
  border-radius: 100%;
  font-size: 40px;
}

.slide-perfilName {
  font-weight: 700;
  text-align: center;
  text-transform:capitalize;
}

.slide-menu {
  display: flex;
  flex-direction: column;
}

.slide-menuElement {
  align-items: center;
  display: flex;
  font-weight: 700;
  height: 50px;
  padding: 0 2rem;
  gap: 0.5rem;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: #FDD429;
  background: none;
  border: none;

  i {
    font-size: 20px;
  }

  &:hover {
    background-color: #0F141E;
  }
}

.activo {
  background-color: #0F141E;
}

.component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.component-wrapper {
  width: 80%;
  
}

.review {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.review-buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
  position: relative;
}

.review-button {
  background-color: #242A38;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #FDD429;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: calc(100%/5);

  .startWidget {
    label {
      font-size: 15px !important;
    }
  }
}

.review-buttonTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.review-body {
  display: grid;
  grid-auto-rows: 12rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 2fr));
  gap: 1rem;
}

.review-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid #8D8D8D;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.review-title {
  font-size: 18px;
  font-weight: 700;
}

.review-info {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-weight: 700;
  justify-content: flex-end;
  div {
    display: flex;
    gap: 0.2rem;
  }
  i {
    color: #242A38;
    font-size: 1.2rem;
  }

  div:nth-child(2) {
    cursor: pointer;
  }
}

.review-cal {
  display: flex;
  justify-content: flex-end;
}


.review-bodyDrivers {
  width: 100%;
  border: 2px solid #242A38;
  border-radius: 0.5rem;
  color: #242A38;
}

.review-bodyTableHead {
  display: flex;
  border-bottom: 2px solid #242A38;
  padding: 1rem 0;
  font-weight: 700;
  div { 
    display: flex;
    justify-content: center;
    width: calc(100%/3);
  }
}

.review-bodyTable {
  .review-bodyTableItem:not(:last-child) {
    border-bottom: 2px solid #242A38;
  }
}

.review-bodyTableItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  div { 
    display: flex;
    justify-content: center;
    width: calc(100%/3);
    flex-direction: row-reverse; 
    padding: 0.2rem; 
  }

  &:hover {
    background-color: #f1f1f1;
  }
  label {
    font-size: 15px !important;
  }
}

.pag {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;

  button {
    background-color: white;
    border: none;
    border-bottom: 1px solid #D4D4D4;
    cursor: pointer;
  }
}

.modalReview {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1.5rem;
}

.ModalReview-title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.codigoQR {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2.5rem;
  background-color: #FDD429;
  border-radius: 0.5rem;
  position: absolute;
  left: -1000%;
}

.codigoQR-text {
  color: #242A38;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.codigoQR-text-title {
  font-weight: 700;
  font-size: 1.5rem;
  max-width: 300px;
}

.codigoQR-text-body {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  text-align: center;

  span:first-child {
    font-weight: 700;
    font-size: 1.1rem;
  }
}

.codigoQR-text-logo {

  img {
    width: 150px;
  }
}

.codigoQR-text-imagen {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
  }
}

.qrImagen {
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  path:last-child {
    fill: #242A38;
  }
}

.textQR {
  color: #242A38;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.nEmpleado {
  color: #242A38;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
}

.sectionQrImagen {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

.sectionQR {
  display: flex;
  position: relative;
}

.arrowQR {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  font-size: 2.5rem;
  color: #242A38;
  margin-right: -30px;
  margin-bottom: 20px;
  i {
    transform: scaleX(-1) rotate(-50deg);
  }
}

.review-input {

  input {
    width: 100%;
    height: 20px;
    background: none;
    border: none;
    border-bottom: 1px solid #FDD429;
    color: #FDD429;
  }
}

.review-selectConductores {
  position: absolute;
  margin-top: 80px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #242A38;
  border-radius: 0.5rem;
  
  div {
    padding: 0.5rem;
    &:hover {
      background-color: #0F141E;
      border-radius: 0.5rem;
    }
  }
}

.review-buttonReset {
  display: none;
}

.ModalReview-Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  .startComponent {
    label {
      font-size: 15px !important;
    }
  }
}

.ModalReview-subtitle {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.ModalReview-name{
  font-size: 18px;
  font-weight: 700;
  color: #242A38;
  text-transform: capitalize;
}

.review-text p{
  text-transform: lowercase;
  color: #242A38;
}


.review-text p::first-letter{
  text-transform: uppercase;
}

.Modal-form-input-file {
  display: none;
}

.formulario-autos {
  flex-direction: column;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 350px;
  display: flex;
  flex-direction: column;
  background-color: #242A38;
  color: #FDD429;
  padding: 1rem;
  border-radius: 1rem;

  gap: 1.5rem;
}

.login-container-logo {
  display: flex;
  justify-content: center;

  img {
    width: 250px;
  }
}

.login-container-inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.login-container-input {
  max-width: 100%;

  input {
    width: calc(100% - 1.5rem);
    padding: 0.5rem;
    background: none;
    border: 2px solid #FDD429;
    border-radius: 0.5rem;
    color: #FDD429;
  }
}

.login-container-boton {
  
  button {
    background-color: #FDD429;
    color: #242A38;
    border: none;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 100%;
  }
}

.driver-Info {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
  display: none;
  .driver-info-div  {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .startComponent {
    margin: auto auto auto 0;
    label {
      font-size: 15px !important;
    }
  }
}

.review-fechaNew {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #FDD429;
  padding-bottom: 0.2rem;
}

.review-habilitado {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  background-color: #1be128;
  color: #fff;
}

.review-inhabilitado {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  background-color: #e1221b;
  color: #fff;
}

.modalReporte {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  .button-reporte {
    background-color: #242A38;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #FDD429;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
  }
}

.component-notification {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: end;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.component-notification-container::-webkit-scrollbar {
  width: 5px;     /* Tamaño del scroll en vertical */
}

.component-notification-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.activeNotification {
  display: none;
}

.component-notification-container {
  z-index: 100;
  position: absolute;
  margin-top: 40px;
  width: 300px;
  max-height: 300px;
  background-color: white;
  top: 0;
  font-size: 0.9rem;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;

  div {
    padding: 10px 10px;
  }

  div:not(:last-child) {
    border-bottom: 1px solid black;
  }

  div:hover {
    background-color: #ebebeb;
  }
}

.component-notification-icon {
  position: relative;
}

.component-notification-alertIcon {
  top: -0.8rem;
  left: 0.3rem;
  border-radius: 1rem;
  padding: 5px;
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: red;
  color: white;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationActiive {
  border: 0 !important;
  border-radius: 1rem;
  background-color: #FDD429;
  width: 15px;
  aspect-ratio: 1/1;
  padding: 0 !important;
}

.component-activeNotification {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  span {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
}

.component-notification-date {
  font-size: 0.6rem;
}