.Modal-form-input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  label {
    text-align: left;
    font-size: 14px;
    color: #0F141E;
    font-weight: 700;
    width: 100%;
  }
  
  input {
    border-radius: 1rem;
    border: 2px solid #D4D4D4;
    padding: 0rem 20px;
    height: 50px;
    width: 240px;
    font-size: 15px;
  }

  select {
    border-radius: 1rem;
    border: 2px solid #D4D4D4;
    padding: 0rem 20px;
    height: 50px;
    width: 100%;
    font-size: 15px;
  }

  .react-datepicker__navigation  {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
  }
  
  button {
    background-color: #FDD429;
    border-radius: 5px;
    height: 50px;
    border: none;
    color: white;
    font-size: 18px;
    width: 270px;
    cursor: pointer;
    color: #0F141E;
    font-weight: 700;
  }
}

.button-formularios {
  background-color: #FDD429;
  border-radius: 5px;
  height: 50px;
  border: none;
  color: white;
  font-size: 18px;
  width: 270px;
  cursor: pointer;
  color: #0F141E;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal-Input {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  
}
.Modal-auto {
  position: relative;
  display: flex;
  flex-direction: column;
}

.Modelo-auto-search {
  display: none;
  position: absolute;
  background-color: white;
  width: 280px;
  border: 1px solid #8D8D8D;
  border-radius: 0.5rem;
  top: 0;
  margin-top: 28%;
  overflow: auto;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    padding: 10px 40px;
    cursor: pointer;

    &:hover {
      background-color: #D4D4D4;
    }
  }
}

.Modal-auto-edit {
  position: relative;
  display: flex;
  align-items: center;
}

.Modal-deleteAuto {
  position: absolute;
  right: 0;
  margin-right: 25px;
  cursor: pointer;
}

.button-guardarEditar {
  display: flex;
  align-items: center;
}