.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index:999;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  padding: 1.5rem;
  height: auto;
  border-radius: 0.5rem;
  background-color: white;
  min-width: 300px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  span {
    cursor: pointer;
  }
}

.Modal-form-input {
  display: flex;
  align-items: center;
  justify-self: center;
}

.Modal-form-input-img {
  width: 200px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  &::before {
    content: '+';
    visibility: hidden;
    opacity: 0;
    width: 20%;
    aspect-ratio: 1/1;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 0.5s ease-in-out;
    color: #242A38;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 30px;
  }

  &:hover::before {
    opacity: 0.3;
    visibility: visible;
  }
}

.Modal-form-input-img img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  cursor: pointer;
}

.Modal-form-inputs {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
  
}