body {
  font-family: 'Inter', sans-serif;
  margin: 0;
}

input {
  &:placeholder-shown {
    opacity: 0.8;
  }
}

textarea {
  &:placeholder-shown {
    opacity: 0.8;
  }
}

.form {
  height: 100vh;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  padding: 2rem 0;
}

.form-logo {
  width: 200px;
  img {
    width: 100%;
  }
}

.startWidget {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  input {
    display: none;
  }
  label {
    float: right;
    transition: all 0.2s ease;
    width: auto !important;
    font-size: 22px !important;
    color: #D4D4D4 !important;
  }

  input:not(:checked) ~ label:hover,
  input:not(:checked) ~ label:hover ~ label {
    color: #FDD429 !important;
  }

  input:checked ~ label {
    color: #FDD429 !important;
  }
}
.activeStar {
  color: #FDD429;
}


.startComponent {
  input {
    display: none;
  }
  label {
    float: right;
    transition: all 0.2s ease;
    width: auto !important;
    font-size: 30px !important;
    padding: 0 0.5rem;
  }
  input:checked ~ label {
    color: #FDD429;
  }
}

.tooltiptext {
  width: 200px;
  padding: 0.5rem;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  font-size: 13px;
  z-index: 1;
  top: 120%;
  transition: opacity 0.3s; 
  border: 1px solid black;
  display: none;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    transform: rotate(180deg);
    bottom: 100%;
    left: 50%;
    margin-left: -80px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
.form-title {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form-quantity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-bottom: 1px solid rgb(228, 227, 227);
  padding-bottom: 1rem;
}

.selectLanguage {
  border-radius: 1rem;
  border: 2px solid #D4D4D4;
  padding: 0rem 20px;
  height: 50px;
  width: 210px;
  font-size: 15px;
}

.languageContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}

.languageSelect {
  width: 50px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #D4D4D4;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;

  img {
    width: 20px;
    aspect-ratio: 1/1;
  }
}

.languageContainerOption {
  margin-top: 30%;
  border-radius: 0.5rem;
  z-index: 1000;
  position: absolute;
  border: 1px solid #D4D4D4;
  display: none;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  background-color: white;
  width: 100px;
  text-align: left;

  a {
    color: #8D8D8D;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  img {
    width: 20px;
  }
}

.form-circles {
  input {
    display: none;
  }

  label {
    float: right;
    transition: all 0.2s ease;
    border: 4px solid #00aa6c;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    width: 25px;
    cursor: pointer;
    margin-right: 5px;
  }

  input:not(:checked) ~ label:hover,
  input:not(:checked) ~ label:hover ~ label {
    background-color: #00aa6c;
  }

  input:checked ~ label {
    background-color: #00aa6c;
  }

  input:checked ~ form {
    display: block;
  }
}

.form-label {
  font-size: 18px;

  span {
    font-size: 13px;
    color: grey;
  }
}

.form-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  button {
    background-color: #FDD429;
    border-radius: 1rem;
    height: 50px;
    border: none;
    color: #242A38;
    font-size: 14px;
    font-weight: 700;
    width: 250px;
    margin-bottom: 2rem;
    cursor: pointer;
  }
}

.form-input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
  width: 250px;
  label {
    text-align: left;
    font-size: 14px;
    color: white;
    font-weight: 700;
    width: 100%;
  }
  
  input {
    border-radius: 1rem;
    border: 2px solid #D4D4D4;
    padding: 0rem 20px;
    height: 50px;
    width: 210px;
    font-size: 15px;
    color: white;
    background: none;
    &::placeholder {
      color: white;
    }
  }

  select {
    border-radius: 1rem;
    border: 2px solid #D4D4D4;
    padding: 0rem 20px;
    height: 50px;
    width: 100%;
    font-size: 15px;
  }
  
  textarea {
    height: 80px;
    border-radius: 1rem;
    border: 2px solid #D4D4D4;
    padding: 1rem 20px;
    width: 210px;
    font-size: 15px;
    background: none;
    color: white;
    &::placeholder {
      color: white;
    }
  }
}
.searchResult {
  position: absolute;
  top: 0;
  margin-top: 80px;
  width: 250px;
  max-height: 220px;
  overflow-y: auto;

  border-radius: 1rem;
  background-color: white;
  border: 2px solid #D4D4D4;
  z-index: 100;
  
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    cursor: pointer;
    list-style: none;
    padding: 0.5rem 0.5rem;
    &:hover {
      background-color: #d5d3d3;
    }
  }

}
.form-question {
  width: 100%;
  border-top: 2px solid #242A38;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  padding-top: 2rem;
  background-color: #242A38;
}

@include breakpoint('medium-high') {
  .form-wrapper {
    justify-content: center;
    width: 300px;
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .form {
    display: grid;
    place-items: center;
    height: 100vh;
  }
}

.buttonLoading {
  height: 10px !important;
  position: relative;
  overflow: hidden;
  background-color: #DEDEDE !important;
}

.buttonLoading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  aspect-ratio: 1/1;
  background-color: #FDD429;
  animation: load 1.5s infinite linear;
}

@keyframes load {
  0% {
    transform: translateX(-20rem);
  }
  100% {
    transform: translateX(20rem);
  }
}

.form-tnks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerLoading {
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000000;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.loadingBar {
  width: 200px;
  height: 10px;
  background-color: #FDD429;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0 0.2rem;

  div {
    height: 5px;
    background-color: #242A38;
    border-radius: 1rem;
    transition: 1s ease 0.3s;
  }
}

.containerLoading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.containerLoading-slide {
  width: 200px;
  display: flex;
  align-items: start;
  gap: 1rem;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow: hidden;
}

.containerLoading-imagen {
  width: 200px;
  aspect-ratio: 1/1;
  background-size: cover;
}

.containerLoading-text {
  font-size: 11px;
  width: 200px;
  font-weight: 700;
  text-align: center;
}

.containerLoading-containerTanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  text-align: center;
  gap:2rem;

  img {
    width: 150px;
  }

  i {
    font-size: 2.5rem;
    color: #242A38;
  }
}